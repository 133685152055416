<div
  class="flex cursor-pointer items-center text-base font-medium text-cca-green-600"
  (click)="isShowMoreChanged.emit()"
>
  <span>
    {{ isShowMore ? showMoreLabel : showLessLabel }}
  </span>
  <cca-icon
    class="mb-0.5 ml-1"
    [icon]="isShowMore ? 'chevron-down' : 'chevron-up'"
  ></cca-icon>
</div>
